<template>
  <div :class="['header', size]">
    <div class="title">
      <span v-if="code">{{ code }}</span>
      <h2 v-if="title">{{ title }}</h2>
    </div>
    <div class="header__leftside">
      <slot name="left"></slot>
    </div>

    <div class="header__rightside">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    title: {
      type: String,
    },
    code: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.header {
  top: 0;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #222;
  height: 60px;
  padding: 0 8px;
  &.small {
    height: 40px;
  }
  .title {
    display: flex;
    align-items: center;
    span {
      background: #555;
      display: inline-block;
      padding: 4px 8px;
      font-size: 22px;
      color: #fff;
      margin-right: 8px;
      text-transform: uppercase;
      border-radius: 3px;
    }
    h2 {
      margin: 0;
      text-transform: uppercase;
      color: #fff;
    }
  }
  &__employee {
    display: flex;
    align-items: center;
    background: #444;
    color: #fff;
    margin-left: 12px;
    padding: 16px 20px;
    height: 46px;
    border-radius: 3px;
  }
  &__rightside {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 6px;
  }
  &__leftside {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 6px;
  }
}
</style>