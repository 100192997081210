import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { createApp } from 'vue'

import '@/assets/app.scss'

import ViewContainer from '@/components/layout/ViewContainer.vue'
import MainHeader from '@/components/layout/MainHeader.vue'
import Spinner from '@/components/Spinner.vue'

Vue.component('ViewContainer', ViewContainer)
Vue.component('MainHeader', MainHeader)
Vue.component('Spinner', Spinner)

Vue.config.productionTip = false



createApp(App).use(store).use(router).mount('#app')