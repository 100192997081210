import Vue from 'vue'
import Vuex from 'vuex'
import device from './modules/device'

import { createStore } from 'vuex'

Vue.use(Vuex)



export default createStore({
  modules: {
    device,
  },
})