<template>
  <transition name="view-container" appear>
    <div v-if="loading > 0" class="view-container-loading">
      <spinner :size="30"></spinner>
    </div>
    <div v-else class="view-container">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ViewContainer',
  props: {
    loading: Number,
  },
}
</script>

<style lang="scss">
.view-container-loading {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.view-container {
  width: 100%;
}
.view-container-leave-active {
  display: none;
}
.view-container-enter-active {
  opacity: 0;
  transform: translate3d(0, 150px, 0);
  transition-property: opacity, transform;
  will-change: opacity, transform;
}
.view-container-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
