import { createRouter, createWebHashHistory } from 'vue-router'

import App from './App.vue'
import ViewMiddle from './ViewMiddle.vue'

const routes = [
  {
    path: '/',
    component: ViewMiddle,
    children: [
      {
        path: '/auth',
        name: 'auth',
        component: () => import('./views/Auth.vue'),
      },
      // {
      //   path: '/auth/login',
      //   name: 'login'
      // }
    ],
  },
  {
    path: '/',
    component: App,
    children: [
      {
        path: '/display',
        name: 'display',
        component: () => import('./views/list/Display.vue'),
        meta: {
          requiredLogin: false,
        },
      },
      {
        path: '/materialunderlimit',
        name: 'materialunderlimit',
        component: () => import('./views/list/MaterialUnderLimit.vue'),
        meta: {
          requiredLogin: false,
        },
      },
      {
        path: '/priorityproductions',
        name: 'priorityproductions',
        component: () => import('./views/list/PriorityProductions.vue'),
        meta: {
          requiredLogin: false,
        },
      },
      {
        path: '/productionphase',
        name: 'productionphase',
        component: () => import('./views/list/ProductionPhase.vue'),
        meta: {
          requiredLogin: false,
        },
      },
      {
        path: '/repairproductions',
        name: 'repairproductions',
        component: () => import('./views/list/RepairProductions.vue'),
        meta: {
          requiredLogin: false,
        },
      },
    ],
  },

  // {
  //   path: '*',
  // }
]

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
